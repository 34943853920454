/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Link } from "gatsby"
import Logo from './logo';

const cssNavbar = css`
    padding-top:1.5rem !important;
    padding-bottom: 1.5rem !important;
`


const Navbar = ({ location, title }) => {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark" css={cssNavbar}>
        <div className="container">
        <Link to="/" className="navbar-brand" ><Logo /></Link>
        </div>
      </nav>
    );
};

export default Navbar;