/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const Logo5 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 500 500">
        <g fill="#fff">
            <path d="M257.75 113.11l.13 346.537H174.82l-.128-285.018 57.263-43.136c.13 0 .258 0 .258-.145l10.318-7.527z"/>
            <path d="M318.884 459.647H264.715l-.129-354.644c0-1.013-.258-1.882-.902-2.75-.774-.869-1.548-1.158-2.45-1.448-.904-.145-1.806 0-2.58.58l-23.99 17.37V31.902l-.128-.145.258-19.107 83.96 51.098z"/>
            <g>
                <path d="M341.114 106.535c.148.297.594.742.89.89 7.863 5.044 15.133 10.83 22.402 17.21 2.374 2.076 4.6 4.302 6.973 6.527 24.48 24.331 39.909 52.519 46.585 84.268 2.077 9.94 3.412 20.176 3.709 30.561v8.16c0 6.825-.594 13.352-1.039 19.88-4.302 38.722-20.622 72.102-49.106 100.587-33.678 33.677-74.18 50.59-121.802 50.59-47.623 0-88.125-16.764-121.802-50.59-29.079-29.078-45.695-63.2-49.552-102.516v-.148c-.742-6.231-1.039-12.462-1.039-18.99v-3.709-.296c0-3.561.297-6.973.742-10.385v-.149c2.967-41.392 19.732-77.146 49.849-107.263 4.599-4.599 9.643-9.05 14.39-13.055 2.82-2.374 5.638-4.6 8.753-6.825 0 0 .149 0 .149-.148.148-.148.593-.297.742-.594l.148-.148c1.929-1.187 3.857-2.67 5.637-3.857.297-.149.742-.594.89-.89.149-.297.297-.89.297-1.187V41.702c0-.593-.148-1.038-.593-1.632-.297-.593-.89-.89-1.484-.89a3.048 3.048 0 0 0-1.632 0c-1.928.742-3.857 1.632-5.934 2.522-.297.149-.89.297-1.484.742-2.967 1.484-6.082 2.819-8.901 4.45-20.622 10.831-39.908 25.073-57.86 43.025-45.546 45.546-68.244 100.735-68.096 165.122.148 64.388 22.847 119.28 68.245 164.974 17.951 17.952 37.386 32.343 58.304 43.024 32.046 16.765 67.652 25.073 106.67 25.073 39.166 0 74.772-8.457 106.818-25.221 20.77-10.83 40.205-25.37 58.008-43.172 13.649-13.5 25.22-28.188 34.864-43.47 6.973-10.978 12.61-22.401 17.506-34.418 10.682-26.705 16.023-55.783 16.023-87.086 0-64.536-22.847-119.577-68.542-165.123-13.945-13.946-28.484-25.518-44.062-35.013-7.418-4.599-15.132-8.753-22.995-12.462-.594-.148-1.039-.296-1.78-.148-.594.148-1.04.297-1.633.89-.296.594-.593 1.039-.593 1.78v60.382c1.038.742 1.038 1.039 1.335 1.484z"/>
            </g>
        </g>
    </svg>
)

const cssLogoElement = css`
    display: flex;
    align-items: center;
`
const cssBrandName = css`
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: 1.5rem;
    color: #fff;
`

const LogoWithName = () => (
    <div css={cssLogoElement}>
        <Logo5 />
        <span css={cssBrandName} className="">Castellan</span>
    </div>
)

export default LogoWithName;