/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { FeaturedBlogSnippet } from './blogSnippet';

const cssHeader = css`
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    `

export const IndexHeader = ( { featuredPost }) => {
    // This will primarily highlight a post.
    return (
        <header css={cssHeader}>
            <div css={css`padding-bottom: 1rem;`}>
                <h1 className="h1" >The Castellan Blog</h1>
            </div>
            <div>
            <FeaturedBlogSnippet post={featuredPost} />
            </div>
        </header>
    )

}

const Header = ({ location, title }) => {
    /*
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location.pathname === rootPath
    */
    return null;
};

export default Header;