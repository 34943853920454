/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Link } from "gatsby"
import Img from 'gatsby-image';

const cssSnippet = css`
    color: inherit;
    text-decoration: inherit;
`

const cssFeatured = css`

`
export const FeaturedBlogSnippet = ( { post }) => {
  const title = post.frontmatter.title || post.fields.slug
  const img = post.frontmatter.featuredimage || 'https://via.placeholder.com/1200x800';
  const img_src = img.childImageSharp;

  return (
    <div className="card" css={cssFeatured}>
        <div className="row g-0">
            <div className="col-md-8">
                <Img className="card-img-top" css={css`height: 35rem;`} {...img_src} alt="fake"></Img>
            </div>
            <div className="col-md-4">
                <div className="card-body">
                    <h2 className="card-title">{title}</h2>
                    <p css={css`min-height:7rem;`} className="card-text" 
                        dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                    />
                    <p className="card-text"><small className="text-muted">{post.frontmatter.date}</small></p>
                    <Link className="btn btn-primary" key={post.fields.slug} to={post.fields.slug} itemProp="url">Read more</Link>
                </div>
            </div>
        </div>
    </div>
  )

}

const cssNoBorders = css`
    border: none !important;
    width: 18rem;
    `


const BlogSnippet = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug
  const img = post.frontmatter.featuredimage || 'https://via.placeholder.com/600x400';
  const img_src = img.childImageSharp;

  return (
      <div className="col">
        <div className="card h-100" css={cssNoBorders}>
                <Link className="card-img-top" css={cssSnippet} key={post.fields.slug} to={post.fields.slug} itemProp="url">
                    <Img className="card-img-top" css={css`height:13rem;`} {...img_src} alt="fake"></Img>
                </Link>
                <div className="card-body">
                    <h2 className="card-title">{title}</h2>
                    <p css={css`min-height:7rem;`} className="card-text" 
                        dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                    />
                    <p className="card-text"><small className="text-muted">{post.frontmatter.date}</small></p>
                    <Link className="btn btn-primary" key={post.fields.slug} to={post.fields.slug} itemProp="url">Read more</Link>
                </div>
        </div>
    </div>
  )
}

export default BlogSnippet;