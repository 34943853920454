/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import Header from './header';
import Footer from './footer';
import Navbar from "./nav";

const cssRoot = css`
  min-height: 70vh;
`

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div>
    <Navbar />
    <div className="global-wrapper " data-is-root-path={isRootPath} css={cssRoot}>
      <Header title={title} location={location} />
      <main className="container">{children}</main>
    </div>
    <Footer />
    </div>
  )
}

export default Layout
