/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare, faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
const year = "2021";

const cssSocialLinks = css`
    padding-right: 1rem;
    color: rgba(255, 255, 255, 0.5);
    &:hover {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
    }
 
`
const cssFullFooter = css` 
    background: #262F36;
    padding: 65px 0 55px;
`

const cssFooterLinks = css`
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    &:hover {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
    }
`



const TwitterIcon = () => <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
const FBIcon = () => <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
const LinkedInIcon = () => <FontAwesomeIcon icon={faLinkedin} size="2x" />

const cssHeadings = css`
    color: #fff;
`

const FullFooter = (props) => (
<footer css={cssFullFooter} >
 <div className="container">
    <div className="row">
      <div className="col-md-5 mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase" css={cssHeadings}>About</h6></li>
          <li className="text-muted">
             Our mission is to create buildings that learn and adapt to their residents to provide a next generation living experience.
          </li>
          <li className="text-muted">Copyright © {year} Keepium</li>
          <li css={css`margin-top: 1rem;`}>
            <a css={cssSocialLinks} role="button" aria-label="Facebook" href="https://www.facebook.com/CastellanLiving/"><FBIcon /></a>
            <a css={cssSocialLinks} role="button" aria-label="Twitter" href="https://twitter.com/castellanLiving"><TwitterIcon /></a>
            <a css={cssSocialLinks} role="button" aria-label="LinkedIn" href="https://www.linkedin.com/company/castellanliving"><LinkedInIcon /></a>
          </li>
        </ul>
      </div>
      <div className="col-md-2 ml-auto mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase" css={cssHeadings}>Products</h6></li>
          <li>
            <a css={cssFooterLinks} href="https://castellan.co/presence">Presence</a>
          </li>
          <li>
            <a css={cssFooterLinks} href="https://castellan.co/booking">Booking</a>
          </li>
          {/*
          <li>
            <Link css={cssFooterLinks} to="/social">Social</Link>
          </li>
          */}
        </ul>
      </div>
      <div className="col-md-2 mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase" css={cssHeadings}>Company</h6></li>
          <li>
            <a css={cssFooterLinks} href="https://castellan.co/about">About</a>
          </li>
        </ul>
      </div>
      <div className="col-md-2 mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase" css={cssHeadings}>Resources</h6></li>
          <li>
            <a css={cssFooterLinks} href="https://www.iubenda.com/privacy-policy/8252473">Privacy &amp; Terms</a>
          </li>
          <li>
            <a css={cssFooterLinks} href="https://castellan.co/contact">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </footer>
);


export default FullFooter;